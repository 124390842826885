import { Router } from '@solidjs/router';
import { FileRoutes } from '@solidjs/start/router';
import { MetaProvider } from '@solidjs/meta';
import { useTrackEvent, useTrackPageview } from '@troon/analytics';
import { UIProvider } from '@troon/ui';
import './app.css';
import { Suspense } from 'solid-js';
import { withSentryRouterRouting } from '@sentry/solidstart/solidrouter';
import { ErrorBoundary } from './components/error-boundary';
import { UserContext } from './providers/user';
import { ServiceWorker } from './providers/service-worker';
import { SkipToMain } from './components/skip-to-main';
import { UtmProvider } from './providers/utm';
import { getBaseUrl } from './modules/schema/base-url';

const SentryRouter = withSentryRouterRouting(Router);

export default function App() {
	const track = useTrackEvent();

	return (
		<SentryRouter
			explicitLinks
			preload
			root={(props) => (
				<UtmProvider>
					<ServiceWorker>
						<MetaProvider>
							<UIProvider
								host={getBaseUrl()}
								onDialogOpened={(key) => track('dialogOpened', { key })}
								onDialogClosed={(key) => track('dialogClosed', { key })}
							>
								<UserContext>
									<Suspense>
										<Tracker />
										<SkipToMain id="content" />
										<ErrorBoundary>
											<Suspense>{props.children}</Suspense>
										</ErrorBoundary>
									</Suspense>
								</UserContext>
							</UIProvider>
						</MetaProvider>
					</ServiceWorker>
				</UtmProvider>
			)}
		>
			<FileRoutes />
		</SentryRouter>
	);
}

function Tracker() {
	useTrackPageview();
	return null;
}
